import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import { Step } from 'modules/digital-threads-mapping-wizard/types';
import { COMBINE_COLUMN } from '../../constants';

export const handleAddCombineColumn = (setSteps, selectedStep, layoutRef, handleOpenUpdateCombineColumn) => {
  return ({
    x: shiftX,
    y: shiftY,
    start,
    end,
    title,
    shapeId,
    skipModalOpen = false,
    shiftLeft = false,
    shiftRight = false,
  }) => {
    const { x, y, scale } = layoutRef.current;

    const boardMaxScale = 10;
    const gap = 10;
    const shapeWidth = 130;
    const shapeHeight = 50;
    const shiftSize = shapeWidth + gap;
    const shiftRightVal = shiftRight ? shiftSize * -1 : 0;
    const shift = shiftLeft ? shiftSize : shiftRightVal;

    const translateX = ((shapeWidth / 2 + shift) / boardMaxScale) * scale;
    const translateY = (shapeHeight / 4 / boardMaxScale) * scale;

    setSteps((s) =>
      s.map((step) => {
        if (step.id === selectedStep) {
          const { shapes, connections } = step.config.board;
          const color = `#${Math.random().toString(16).substring(2, 8)}`;

          return {
            ...step,
            config: {
              ...step.config,
              board: {
                ...step.config.board,
                shapes: shapes.concat({
                  id: shapeId,
                  height: shapeHeight,
                  width: shapeWidth,
                  metadata: {},
                  title,
                  type: COMBINE_COLUMN,
                  xCoordinate: (x * -1 + shiftX - translateX) / scale,
                  yCoordinate: (y * -1 + shiftY - translateY) / scale,
                }),
                connections: connections
                  .concat({
                    connectionId: uuid(),
                    start,
                    end: shapeId,
                    headSize: 4,
                    tailSize: 4,
                    curveness: 0.5,
                    color,
                    endPosition: 'auto',
                    startPosition: ['left', 'right'],
                    uniqKey: Math.random(),
                  })
                  .concat({
                    connectionId: uuid(),
                    end,
                    start: shapeId,
                    headSize: 4,
                    tailSize: 4,
                    curveness: 0.5,
                    color,
                    endPosition: ['left', 'right'],
                    startPosition: 'auto',
                    uniqKey: Math.random(),
                    metadata: {
                      switchTail: true,
                    },
                  }),
              },
            },
          };
        }

        return step;
      }),
    );

    if (!skipModalOpen) {
      handleOpenUpdateCombineColumn({ shapeId, forceDelete: true });
    }
  };
};

export const handleDeleteCombineColumn = (setSteps, selectedStep) => {
  return (shapeId: string) => {
    setSteps((ps) =>
      ps.map((step) => {
        if (step.id === selectedStep) {
          const clone: Step = cloneDeep(step);
          clone.config.board.shapes = clone.config.board.shapes.filter((s) => s.id !== shapeId);
          clone.config.board.connections = clone.config.board.connections.filter(
            (c) => c.start !== shapeId && c.end !== shapeId,
          );
          return clone;
        }
        return step;
      }),
    );
  };
};

export const handleCloseUpdateCombineModal = (
  editableCombineColumn,
  deleteCombineColumnHandler,
  handleCloseUpdateCombineColumn,
) => {
  return (arg) => {
    if (!arg?.skipDelete) {
      deleteCombineColumnHandler(editableCombineColumn.shapeId);
    }
    handleCloseUpdateCombineColumn();
  };
};

export const handleUpdateCombineColumn = (setSteps, selectedStep) => {
  return (shapeId, title) => {
    setSteps((ps) =>
      ps.map((step) => {
        if (step.id === selectedStep) {
          const clone: Step = cloneDeep(step);

          clone.config.board.shapes = clone.config.board.shapes.map((cs) => {
            if (cs.id === shapeId) {
              return {
                ...cs,
                title,
              };
            }
            return cs;
          });

          return clone;
        }

        return step;
      }),
    );
  };
};
