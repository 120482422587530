import { memo, useContext } from 'react';

import { WizardContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';

import { StepType } from 'modules/digital-threads-mapping-wizard/constants';
import Transformation from './Transformation';
import EditColumns from './EditColumns';
import Blend from './Blend';
import Combine from './Combine';
import Merge from './Merge';
import Explode from './Explode';
import Group from './Group';
import Expand from './Expand';

const Builder = () => {
  const { stepData } = useContext(WizardContext);

  if (!stepData) {
    return null;
  }

  if (stepData.type === StepType.TRANSFORM) {
    return <Transformation />;
  }

  if (stepData.type === StepType.BLEND) {
    return <Blend />;
  }

  if (stepData.type === StepType.COMBINE) {
    return <Combine />;
  }

  if ([StepType.ADD_COLUMNS, StepType.DELETE_COLUMNS].includes(stepData.type)) {
    return <EditColumns />;
  }

  if (stepData.type === StepType.MERGE) {
    return <Merge />;
  }

  if (stepData.type === StepType.EXPLODE) {
    return <Explode />;
  }

  if (stepData.type === StepType.GROUP) {
    return <Group />;
  }

  if (stepData.type === StepType.EXPAND) {
    return <Expand />;
  }
  return null;
};

export default memo(Builder);
