import { memo } from 'react';
import { CardActions as MuiCardActions, Tooltip, Button } from '@material-ui/core';
import { Done, Error } from '@material-ui/icons';

import { useStyles } from './styles';

const wrapWithToptip = (wrap, child, title) => {
  if (wrap) {
    return <Tooltip title={title}>{child}</Tooltip>;
  }

  return child;
};

const Actions = ({ onSuccess, onFail, buttonsDisabled }) => {
  const classes = useStyles();

  return (
    <MuiCardActions className={classes.cardActions}>
      {wrapWithToptip(
        !buttonsDisabled,
        <Button
          disabled={buttonsDisabled}
          classes={{ disabled: classes.cardActionDisabled }}
          className={classes.cardAction}
          onClick={(e) => {
            e.stopPropagation();
            onSuccess();
          }}
        >
          <Done color="primary" />
        </Button>,
        'Approve',
      )}

      {wrapWithToptip(
        !buttonsDisabled,
        <Button
          disabled={buttonsDisabled}
          classes={{ disabled: classes.cardActionDisabled }}
          className={classes.cardAction}
          onClick={(e) => {
            e.stopPropagation();
            onFail();
          }}
        >
          <Error color="error" />
        </Button>,
        'Reject',
      )}
    </MuiCardActions>
  );
};

export default memo(Actions);
