import { makeStyles } from '@material-ui/core';
import { successColor, dangerColor } from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    position: 'relative',
    borderColor: '#8f8f8f',
    borderRadius: 8,
    boxShadow: '0 8px 8px 0 #8f8f8f',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0',
    paddingLeft: 24,
    paddingRight: 24,
    borderBottom: `1px solid #8f8f8f`,
    position: 'relative',
  },
  headerItemMenuWrap: {
    position: 'absolute',
    top: 0,
    right: 5,
    width: '20px',
    height: '20px',
  },
  headerItemMenu: {
    width: '20px',
    height: '20px',
  },
  headerItem: {
    color: '#000000',
  },
  headerItemText: {
    padding: '5px',
    flexGrow: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: `10px 0 !important`,
  },
  columnRow: {
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    boxShadow: '0 0px 4px 0 #8f8f8f',
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
    justifyContent: 'space-between',
    gap: 10,
    borderColor: '#8f8f8f',
    borderRadius: 4,
  },
  selectedRow: {
    backgroundColor: '#DFF0D8',
  },
  selectedKeepRow: {
    backgroundColor: '#d0f4d0',
  },
  selectedExcludeRow: {
    backgroundColor: '#F8D7DA',
  },
  selectedValueRow: {
    backgroundColor: 'rgba(25, 117, 210, 0.2)',
  },
  columnName: {
    flexGrow: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  checkIcon: {
    width: 12,
    flexShrink: 0,
    cursor: 'pointer',
  },
  trashIcon: {
    width: 12,
    flexShrink: 0,
    cursor: 'pointer',
  },
  selectedIcon: {
    '& > *': {
      fill: successColor,
    },
  },
  icon: {
    width: '100% !important',
    height: '100% !important',
  },
  redIcon: {
    fill: dangerColor,
    width: '100% !important',
    height: '100% !important',
  },
  inputField: {
    marginTop: '10px',
    marginBottom: '10px',
  },

  legendText: {
    fontSize: '12px',
    color: 'grey',
  },
  connectionsWrapper: {
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  connectionsWrapperHorizontal: {
    flexDirection: 'column',
  },
  connectionsElement: {
    flex: '1 0',
  },
  editIcon: {
    width: 12,
    flexShrink: 0,
    cursor: 'pointer',
  },
  selectedExpandColumn: {
    backgroundColor: 'rgba(255, 255, 0, 0.3)',
  },
}));
