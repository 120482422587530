import { DataStoreNames } from 'modules/data-stores-custom/contants';
import { FileManagerComponent } from '@syncfusion/ej2-react-filemanager';
import { DataStoresState } from 'modules/data-stores-custom/context/DataStoresState';
import Select from 'modules/shared/components/Select';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import FileManager from 'modules/shared/components/FileManager';
import { Box, Button, CircularProgress } from '@mui/material';
// import ModalContent from 'modules/data-stores-custom/sync-path-scheduler-modal-ui/ModalContent';
import SyncPathEditItem from 'modules/shared/components/SyncPathEditItem';
import { useStyles } from './styles';

const MODAL_ID = 'custom-connections-upload-folder-explorer-modal';
const FORM_ID = 'custom-connections-upload-folder-explorer-modal-form';

const INITIAL_VALUES = {
  name: '',
  scheduler: '',
  schedulerValue: null,
  schedulerLabel: '',
  filter: '',
  unzip: false,
  loadDeltaLake: true,
};

enum Steps {
  FIRST = 'first',
  SECOND = 'second',
}

type Item = {
  hdfsPath: string;
  isFile: boolean;
  name: string;
  scheduler: string;
  schedulerValue: string[] | null;
  schedulerLabel: string;
  filter: string;
  unzip: boolean;
  loadDeltaLake: boolean;
};

const CustomConnectionsUploadFolderExplorer = ({
  isOpen,
  onClose,
  projectId,
  connection,
  executing,
  onCreate,
  getItems,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState<Steps>(Steps.FIRST);
  const [selectedConnection, setSelectedConnection] = useState('');
  const [path, setPath] = useState('/');
  const [items, setItems] = useState<Array<Item>>([]);
  const opened = useRef(false);

  const { dataStoreConnections } = useContext(DataStoresState);
  const fileManager = useRef<FileManagerComponent | null>(null);

  useEffect(() => {
    if (!isOpen) {
      opened.current = false;
      setStep(Steps.FIRST);
      setSelectedConnection('');
      setPath('/');
      setItems([]);
    }
  }, [isOpen, getItems]);

  useEffect(() => {
    if (isOpen && !opened.current) {
      opened.current = true;
      const selectedPathes = getItems().map((it) => ({
        hdfsPath: it.hdfsPath,
        isFile: it.isFile,
        ...INITIAL_VALUES,
      }));
      setItems(selectedPathes);
    }
  }, [isOpen, getItems]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={onClose}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={onClose}>
        {step === Steps.FIRST && 'Select folder'}
        {step === Steps.SECOND && 'Configure Export'}
      </Title>
      <Content className={classes.dialogContent}>
        {step === Steps.FIRST && (
          <>
            <Select
              className={classes.select}
              value={selectedConnection}
              items={dataStoreConnections.map((c) => ({
                value: c.connectionId,
                label: `${c.name} (${DataStoreNames[c.type]})`,
              }))}
              onChange={(it) => {
                setSelectedConnection(it as string);
              }}
              placeholder="Select Connection"
            />
            <div style={{ flex: 1, display: 'flex' }}>
              {selectedConnection && (
                <FileManager
                  id="custom-data-source"
                  path="/data-stores"
                  allowFolderCreate
                  key={`${projectId}-${selectedConnection}`}
                  ref={fileManager}
                  beforeSendBody={{
                    connection,
                    projectId,
                    dataStoreConnection: selectedConnection,
                    skipFiles: true,
                  }}
                />
              )}
            </div>
          </>
        )}
        {step === Steps.SECOND && (
          <Box
            component="form"
            id={FORM_ID}
            onSubmit={(e) => {
              e.preventDefault();

              onCreate(
                selectedConnection,
                items.map((it) => ({
                  name: it.name,
                  filter: it.filter,
                  unzip: it.unzip,
                  loadDeltaLake: it.loadDeltaLake,
                  hdfsPath: it.hdfsPath,
                  isFile: it.isFile,
                  scheduler: it.scheduler,
                  schedulerLabel: it.schedulerLabel,
                  externalPath: path,
                  schedulerPattern: it.schedulerValue ? it.schedulerValue.join(' ') : '',
                })),
              );
            }}
          >
            {items.map((item) => {
              return (
                <SyncPathEditItem
                  key={item.hdfsPath}
                  itemName={item.hdfsPath}
                  item={item}
                  onChange={(nextItem) => {
                    const newItems = items.map((i) => (i.hdfsPath === nextItem.hdfsPath ? nextItem : i));
                    setItems(newItems);
                  }}
                  disabled={executing}
                />
              );
            })}
          </Box>
        )}
      </Content>
      {step === Steps.FIRST && (
        <Actions style={{ justifyContent: 'space-between' }}>
          <div className={classes.remarks}>* Your files will be uploaded in current folder</div>
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={!selectedConnection}
            onClick={() => {
              setPath(`/${fileManager.current!.pathNames.slice(1).join('/')}`);
              setStep(Steps.SECOND);
            }}
          >
            Next
          </Button>
        </Actions>
      )}
      {step === Steps.SECOND && (
        <Actions>
          <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={executing}>
            {executing ? <CircularProgress className={classes.progress} size={24} /> : 'Submit'}
          </Button>
        </Actions>
      )}
    </Dialog>
  );
};

export default memo(CustomConnectionsUploadFolderExplorer);
