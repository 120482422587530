import { Button, Box, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useContext, useState, useEffect } from 'react';
import { WizardContext, WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';
import { GroupingRule } from 'modules/digital-threads-mapping-wizard/types';
import { useStyles } from './styles';

const MODAL_ID = 'group-rule-modal';
const FORM_ID = 'group-rule-modal-form';

const rules: GroupingRule['type'][] = [
  'COUNT',
  'COUNT_NON_EMPTY',
  'COUNT_DISTINCT',
  'JOIN',
  'JOIN_DISTINCT',
  'MAX',
  'MIN',
  'SORTMAX',
  'SORTMIN',
];

const GroupRuleModal = () => {
  const classes = useStyles();

  const { isGroupRuleModalOpen, currentGroupColumn, stepData } = useContext(WizardContext);
  const { closeGroupRuleModal, updateStepConfig } = useContext(WizardHandlersContext);

  const [selectedRule, setSelectedRule] = useState<GroupingRule['type'] | undefined>(undefined);
  const [hasExistingRule, setHasExistingRule] = useState(false);

  useEffect(() => {
    if (isGroupRuleModalOpen) {
      const rule = stepData?.config.groupConfig?.rules.find((r) => r.key === currentGroupColumn);
      if (rule) {
        setSelectedRule(rule.type);
        setHasExistingRule(true);
      } else {
        setSelectedRule(undefined);
        setHasExistingRule(false);
      }
    }
  }, [currentGroupColumn, isGroupRuleModalOpen, stepData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedRule) {
      const groupConfig = stepData?.config.groupConfig || {
        caseSensitive: false,
        keys: [],
        values: [],
        rules: [],
      };

      const updatedRules = groupConfig.rules.map((rule) => {
        if (rule.key === currentGroupColumn) {
          return {
            ...rule,
            type: selectedRule,
          };
        }
        return rule;
      });

      if (!groupConfig.rules.find((rule) => rule.key === currentGroupColumn)) {
        updatedRules.push({ key: currentGroupColumn, type: selectedRule });
      }

      const updatedValues = groupConfig.values.includes(currentGroupColumn)
        ? groupConfig.values
        : [...groupConfig.values, currentGroupColumn];

      const updatedKeys = groupConfig.keys.filter((key) => key !== currentGroupColumn);

      updateStepConfig({
        groupConfig: {
          ...groupConfig,
          rules: updatedRules,
          values: updatedValues,
          keys: updatedKeys,
        },
      });

      closeGroupRuleModal();
    }
  };

  const handleDelete = () => {
    const groupConfig = stepData?.config.groupConfig || {
      caseSensitive: false,
      keys: [],
      values: [],
      rules: [],
    };

    const updatedRules = groupConfig.rules.filter((rule) => rule.key !== currentGroupColumn);

    const updatedValues = groupConfig.values.filter((value) => value !== currentGroupColumn);

    updateStepConfig({
      groupConfig: {
        ...groupConfig,
        rules: updatedRules,
        values: updatedValues,
      },
    });

    closeGroupRuleModal();
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={closeGroupRuleModal}
      aria-labelledby={MODAL_ID}
      open={isGroupRuleModalOpen}
      disableAutoFocus
      disableEnforceFocus
    >
      <Title id={MODAL_ID} onClose={closeGroupRuleModal}>
        Select Grouping Rule for "{currentGroupColumn}"
      </Title>
      <Content className={classes.dialogContent}>
        <Box component="form" id={FORM_ID} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="rule-select-label">Rule</InputLabel>
                <Select
                  labelId="rule-select-label"
                  label="Rule"
                  value={selectedRule || ''}
                  onChange={(e) => setSelectedRule(e.target.value as GroupingRule['type'])}
                >
                  {rules.map((rule) => (
                    <MenuItem key={rule} value={rule}>
                      {rule}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Content>
      <Actions>
        <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={!selectedRule}>
          Set Rule
        </Button>

        {hasExistingRule && (
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete Value and Rule
          </Button>
        )}
      </Actions>
    </Dialog>
  );
};

export default memo(GroupRuleModal);
