import {
  Button,
  Card,
  CardContent,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandIcon from '@mui/icons-material/Expand';
import { Check, Key, Delete, Edit } from '@mui/icons-material';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { WizardHandlersContext, WizardContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';
import { memo, useCallback, useContext, useRef, useState, useEffect, useMemo } from 'react';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { StepType } from 'modules/digital-threads-mapping-wizard/constants';
import { MergeColumn } from 'modules/digital-threads-mapping-wizard/types';
import { DraggableShapeProps } from '../../types';
import ColumnCard, { Types } from '../ColumnCard';
import { useStyles } from './styles';

export const MIN_CARD_HEIGHT = 63;
export const ITEM_HEIGHT = 32;
export const PADDING_TOP = 5;

const renderConnectionsShape = (shapeId: string, type: string, cols: string[], horizontal: boolean, classes) => {
  if (cols.length) {
    return (
      <div
        className={classNames(classes.connectionsWrapper, {
          [classes.connectionsWrapperHorizontal]: horizontal,
        })}
      >
        {cols.map((label) => {
          return <div id={`${shapeId}-${type}-${label}`} key={label} className={classes.connectionsElement} />;
        })}
      </div>
    );
  }
};

const SourceDtData = ({ width, height, title, metadata, id: shapeId }: DraggableShapeProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { openImportSourceModal, resetInputCard, refreshCard, updateStepConfig, openGroupRuleModal } =
    useContext(WizardHandlersContext);
  const { stepData } = useContext(WizardContext);
  const { insidePreview } = useContext(PreviewContext);

  const columns = useMemo(() => metadata?.columns || [], [metadata]);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const mergeColumns = useMemo(() => stepData?.config?.mergeColumns || [], [stepData]);
  const explodeConfig = useMemo(
    () => stepData?.config?.explodeConfig || { exclude: [], keep: [], columnName: '', useColumnValue: true },
    [stepData],
  );
  const groupConfig = useMemo(
    () => stepData?.config?.groupConfig || { keys: [], values: [], rules: [], caseSensitive: false },
    [stepData],
  );
  const expandConfig = useMemo(
    () => stepData?.config?.expandConfig || { keep: [], columnDelimiter: '', columnName: '' },
    [stepData],
  );

  const [expandKeep, setExpandKeep] = useState<string[]>(expandConfig.keep);
  const [expandColumnName, setExpandColumnName] = useState<string>(expandConfig.columnName);
  const [columnDelimiter, setColumnDelimiter] = useState<string>(expandConfig.columnDelimiter);

  const [selectedColumns, setSelectedColumns] = useState<MergeColumn[]>(mergeColumns);
  const [selectedKeep, setSelectedKeep] = useState<string[]>(explodeConfig.keep);
  const [selectedExclude, setSelectedExclude] = useState<string[]>(explodeConfig.exclude);
  const [columnName, setColumnName] = useState(explodeConfig.columnName);
  const [useColumnValue, setUseColumnValue] = useState(explodeConfig.useColumnValue);

  const [selectedKeys, setSelectedKeys] = useState<string[]>(groupConfig.keys);
  const [selectedValues, setSelectedValues] = useState<string[]>(groupConfig.values);
  const [caseSensitive, setCaseSensitive] = useState<boolean>(groupConfig.caseSensitive);

  const anchorRef = useRef(null);

  const isMergeStep = stepData?.type === StepType.MERGE;
  const isExplodeStep = stepData?.type === StepType.EXPLODE;
  const isGroupStep = stepData?.type === StepType.GROUP;
  const isExpandStep = stepData?.type === StepType.EXPAND;

  useEffect(() => {
    setSelectedColumns(mergeColumns);
    setSelectedKeep(explodeConfig.keep);
    setSelectedExclude(explodeConfig.exclude);
    setColumnName(explodeConfig.columnName);
    setUseColumnValue(explodeConfig.useColumnValue);
    setSelectedKeys(groupConfig.keys);
    setSelectedValues(groupConfig.values);
    setCaseSensitive(groupConfig.caseSensitive);
    setExpandKeep(expandConfig.keep);
    setExpandColumnName(expandConfig.columnName);
    setColumnDelimiter(expandConfig.columnDelimiter);
  }, [mergeColumns, explodeConfig, groupConfig, expandConfig]);

  useEffect(() => {
    const existingValuesWithRules = columns.filter((col) => groupConfig.rules.some((rule) => rule.key === col));
    setSelectedValues(existingValuesWithRules);
  }, [columns, groupConfig.rules]);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await refreshCard(stepData!.id, shapeId);
    } catch {
      enqueueSnackbar('Error occured while refreshing card', BASE_ERROR_NOTIFICATION_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  };

  const handleColumnSelect = useCallback(
    (col: string) => {
      if (isMergeStep) {
        let updatedColumns;
        const isSelected = selectedColumns.some((c) => c.name === col);

        if (isSelected) {
          updatedColumns = selectedColumns.filter((c) => c.name !== col);
        } else {
          updatedColumns = [...selectedColumns, { name: col, isSelected: true }];
        }

        setSelectedColumns(updatedColumns);

        updateStepConfig({
          mergeColumns: updatedColumns,
        });
      }
    },
    [selectedColumns, updateStepConfig, isMergeStep],
  );

  const handleKeepSelect = (col: string) => {
    if (isExplodeStep) {
      const updatedKeep = selectedKeep.includes(col) ? selectedKeep.filter((c) => c !== col) : [...selectedKeep, col];

      const updatedExclude = selectedExclude.filter((c) => c !== col);

      setSelectedKeep(updatedKeep);
      setSelectedExclude(updatedExclude);

      updateStepConfig({
        explodeConfig: {
          ...explodeConfig,
          keep: updatedKeep,
          exclude: updatedExclude,
        },
      });
    }
  };

  const handleExcludeSelect = (col: string) => {
    if (isExplodeStep) {
      const updatedExclude = selectedExclude.includes(col)
        ? selectedExclude.filter((c) => c !== col)
        : [...selectedExclude, col];

      const updatedKeep = selectedKeep.filter((c) => c !== col);

      setSelectedExclude(updatedExclude);
      setSelectedKeep(updatedKeep);

      updateStepConfig({
        explodeConfig: {
          ...explodeConfig,
          exclude: updatedExclude,
          keep: updatedKeep,
        },
      });
    }
  };

  const handleColumnNameChange = (event) => {
    if (isExplodeStep) {
      const newColumnName = event.target.value;
      setColumnName(newColumnName);
      updateStepConfig({
        explodeConfig: {
          ...explodeConfig,
          columnName: newColumnName,
        },
      });
    }
  };

  const toggleUseColumnValue = () => {
    if (isExplodeStep) {
      const newValue = !useColumnValue;
      setUseColumnValue(newValue);
      updateStepConfig({
        explodeConfig: {
          ...explodeConfig,
          useColumnValue: newValue,
        },
      });
    }
  };

  const toggleCaseSensitive = () => {
    if (isGroupStep) {
      const newValue = !caseSensitive;
      setCaseSensitive(newValue);
      updateStepConfig({
        groupConfig: {
          ...groupConfig,
          caseSensitive: newValue,
        },
      });
    }
  };

  const handleKeySelect = (col: string) => {
    if (isGroupStep) {
      const isKeySelected = selectedKeys.includes(col);
      const updatedKeys = isKeySelected ? selectedKeys.filter((c) => c !== col) : [...selectedKeys, col];

      const updatedValues = selectedValues.filter((value) => value !== col);
      const updatedRules = groupConfig.rules.filter((rule) => rule.key !== col);

      setSelectedKeys(updatedKeys);

      updateStepConfig({
        groupConfig: {
          ...groupConfig,
          keys: updatedKeys,
          values: updatedValues,
          rules: updatedRules,
        },
      });
    }
  };

  const handleValueSelect = (col: string) => {
    if (isGroupStep) {
      openGroupRuleModal(col);
    }
  };

  const handleExpandKeepSelect = (col: string) => {
    const updatedExpandKeep = expandKeep.includes(col) ? expandKeep.filter((c) => c !== col) : [...expandKeep, col];

    if (expandColumnName && !updatedExpandKeep.includes(expandColumnName)) {
      updatedExpandKeep.push(expandColumnName);
    }

    setExpandKeep(updatedExpandKeep);
    updateStepConfig({ expandConfig: { keep: updatedExpandKeep, columnDelimiter, columnName: expandColumnName } });
  };

  const handleExpandColumnSelect = (col: string) => {
    if (expandColumnName === col) {
      const updatedExpandKeep = expandKeep.filter((c) => c !== col);
      setExpandColumnName('');
      setExpandKeep(updatedExpandKeep);
      updateStepConfig({ expandConfig: { keep: updatedExpandKeep, columnDelimiter, columnName: '' } });
    } else {
      let updatedExpandKeep = expandKeep;
      if (!expandKeep.includes(col)) {
        updatedExpandKeep = [...expandKeep, col];
        setExpandKeep(updatedExpandKeep);
      }
      setExpandColumnName(col);
      updateStepConfig({ expandConfig: { keep: updatedExpandKeep, columnDelimiter, columnName: col } });
    }
  };

  const handleColumnDelimiterChange = (event) => {
    const delimiter = event.target.value;
    setColumnDelimiter(delimiter);
    updateStepConfig({
      expandConfig: {
        keep: expandKeep,
        columnDelimiter: delimiter,
        columnName: expandColumnName,
      },
    });
  };

  let dynamicHeightVariable = 0;

  if (isExplodeStep) {
    dynamicHeightVariable = 170;
  } else if (isGroupStep) {
    dynamicHeightVariable = 70;
  } else if (isExpandStep) {
    dynamicHeightVariable = 100;
  }

  const dynamicHeight = PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * columns.length + dynamicHeightVariable;

  const cardHeight = columns.length ? dynamicHeight : height;

  return (
    <Card className={classes.container} style={{ width, height: cardHeight }}>
      <CardContent className={classes.content}>
        <div className={classes.cardHeader}>
          <Typography variant="h6" className={`${classes.headerItem} ${classes.headerItemText}`}>
            {title}
          </Typography>
          <div className={classes.headerItemMenuWrap}>
            <div ref={anchorRef} />
            {!insidePreview && (
              <IconButton disableRipple className={classes.headerItemMenu} size="small" onClick={handleMenuOpen}>
                <MoreVertIcon className={`${classes.headerItem} ${classes.headerItemMenu}`} />
              </IconButton>
            )}
          </div>
        </div>

        {isLoading ? (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {!columns.length ? (
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  onClick={() => {
                    openImportSourceModal(shapeId);
                  }}
                >
                  Import
                </Button>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, position: 'relative' }}>
                {!isMergeStep &&
                  !isExplodeStep &&
                  !isGroupStep &&
                  !isExpandStep &&
                  columns.map((col) => (
                    <ColumnCard type={Types.Start} key={`${shapeId}-${col}`} parentId={shapeId} title={col} />
                  ))}

                {isMergeStep &&
                  columns.map((col) => (
                    <div style={{ padding: '6px 10px' }} key={`${shapeId}-${col}`}>
                      <div
                        className={classNames(classes.columnRow, {
                          [classes.selectedRow]: selectedColumns.some((sc) => sc.name === col),
                        })}
                        style={{
                          height: 20,
                          display: 'flex',
                          alignItems: 'center',
                          borderColor: '#8f8f8f',
                          borderRadius: 4,
                          boxShadow: '0 0px 4px 0 #8f8f8f',
                          padding: '0 4px',
                          justifyContent: 'space-between',
                          gap: 10,
                        }}
                      >
                        <span title={col} className={classes.columnName}>
                          {col}
                        </span>

                        <div
                          className={classes.checkIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleColumnSelect(col);
                          }}
                          role="presentation"
                        >
                          {selectedColumns.some((sc) => sc.name === col) ? (
                            <Key className={classes.icon} />
                          ) : (
                            <Check className={classes.icon} />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                {isExplodeStep &&
                  columns.map((col) => (
                    <div style={{ padding: '6px 10px' }} key={`${shapeId}-${col}`}>
                      <div
                        className={classNames(classes.columnRow, {
                          [classes.selectedKeepRow]: selectedKeep.includes(col),
                          [classes.selectedExcludeRow]: selectedExclude.includes(col),
                        })}
                        style={{
                          height: 20,
                          display: 'flex',
                          alignItems: 'center',
                          borderColor: '#8f8f8f',
                          borderRadius: 4,
                          boxShadow: '0 0px 4px 0 #8f8f8f',
                          padding: '0 4px',
                          justifyContent: 'space-between',
                          gap: 10,
                        }}
                      >
                        <span title={col} className={classes.columnName}>
                          {col}
                        </span>

                        <div
                          className={classes.checkIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleKeepSelect(col);
                          }}
                          role="presentation"
                        >
                          {selectedKeep.includes(col) ? (
                            <Key className={classes.icon} />
                          ) : (
                            <Check className={classes.icon} />
                          )}
                        </div>

                        <div
                          className={classes.trashIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleExcludeSelect(col);
                          }}
                          role="presentation"
                        >
                          <Delete className={selectedExclude.includes(col) ? classes.redIcon : classes.icon} />
                        </div>
                      </div>
                    </div>
                  ))}

                {isGroupStep &&
                  columns.map((col) => (
                    <div style={{ padding: '6px 10px' }} key={`${shapeId}-${col}`}>
                      <div
                        className={classNames(classes.columnRow, {
                          [classes.selectedKeepRow]: selectedKeys.includes(col),
                          [classes.selectedValueRow]: selectedValues.includes(col),
                        })}
                        style={{
                          height: 20,
                          display: 'flex',
                          alignItems: 'center',
                          borderColor: '#8f8f8f',
                          borderRadius: 4,
                          boxShadow: '0 0px 4px 0 #8f8f8f',
                          padding: '0 4px',
                          justifyContent: 'space-between',
                          gap: 10,
                        }}
                      >
                        <span title={col} className={classes.columnName}>
                          {col}
                        </span>

                        <div
                          className={classes.checkIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleKeySelect(col);
                          }}
                          role="presentation"
                        >
                          {selectedKeys.includes(col) ? (
                            <Key className={classes.icon} />
                          ) : (
                            <Check className={classes.icon} />
                          )}
                        </div>

                        <div
                          className={classes.editIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleValueSelect(col);
                          }}
                          role="presentation"
                        >
                          <Edit className={selectedValues.includes(col) ? classes.icon : classes.redIcon} />
                        </div>
                      </div>
                    </div>
                  ))}

                {isExpandStep &&
                  columns.map((col) => (
                    <div style={{ padding: '6px 10px' }} key={`${shapeId}-${col}`}>
                      <div
                        className={classNames(classes.columnRow, {
                          [classes.selectedKeepRow]: expandKeep.includes(col),
                          [classes.selectedExpandColumn]: expandColumnName === col,
                        })}
                        style={{
                          height: 20,
                          display: 'flex',
                          alignItems: 'center',
                          borderColor: '#8f8f8f',
                          borderRadius: 4,
                          boxShadow: '0 0px 4px 0 #8f8f8f',
                          padding: '0 4px',
                          justifyContent: 'space-between',
                          gap: 10,
                        }}
                      >
                        <span title={col} className={classes.columnName}>
                          {col}
                        </span>

                        <div
                          className={classes.checkIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleExpandKeepSelect(col);
                          }}
                          role="presentation"
                        >
                          {expandKeep.includes(col) ? (
                            <Key className={classes.icon} />
                          ) : (
                            <Check className={classes.icon} />
                          )}
                        </div>

                        <div
                          className={classes.editIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleExpandColumnSelect(col);
                          }}
                          role="presentation"
                        >
                          <ExpandIcon className={expandColumnName === col ? classes.icon : classes.redIcon} />
                        </div>
                      </div>
                    </div>
                  ))}

                {isExpandStep && (
                  <>
                    <div style={{ padding: '6px 10px' }}>
                      <TextField
                        label="Column Delimiter"
                        variant="outlined"
                        margin="normal"
                        value={columnDelimiter}
                        onChange={handleColumnDelimiterChange}
                        fullWidth
                        className={classes.inputField}
                      />
                    </div>
                  </>
                )}

                {isExplodeStep && (
                  <>
                    <div style={{ padding: '6px 10px' }}>
                      <TextField
                        label="New Column Name"
                        variant="outlined"
                        margin="normal"
                        value={columnName}
                        fullWidth
                        required
                        error={!columnName}
                        helperText={!columnName ? 'This field is required' : ''}
                        onChange={handleColumnNameChange}
                        className={classes.inputField}
                      />
                      <span className={classes.legendText}>Use Column Value:</span>
                      <Button
                        onClick={toggleUseColumnValue}
                        fullWidth
                        variant="contained"
                        color={useColumnValue ? 'primary' : 'error'}
                        style={{ marginTop: 5 }}
                      >
                        {useColumnValue ? 'TRUE' : 'FALSE'}
                      </Button>
                    </div>
                  </>
                )}

                {isGroupStep && (
                  <>
                    <div style={{ padding: '6px 10px' }}>
                      <span className={classes.legendText}>Case Sensitive:</span>
                      <Button
                        onClick={toggleCaseSensitive}
                        fullWidth
                        variant="contained"
                        color={caseSensitive ? 'primary' : 'error'}
                        style={{ marginTop: 5 }}
                      >
                        {caseSensitive ? 'TRUE' : 'FALSE'}
                      </Button>
                    </div>
                  </>
                )}

                {renderConnectionsShape(shapeId, 'start', columns, true, classes)}
              </div>
            )}
          </>
        )}
      </CardContent>
      <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            resetInputCard(shapeId);
            handleMenuClose();
          }}
        >
          Reset
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRefresh();
            handleMenuClose();
          }}
        >
          Refresh
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default memo(SourceDtData);
