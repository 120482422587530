import { memo, Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { parseExpression } from 'cron-parser';
import { confirm } from 'devextreme/ui/dialog';
import { addMinutes } from 'date-fns';
import { DataGrid as DataGridType } from 'devextreme-react';

import { Button, Column } from 'devextreme-react/data-grid';
import Swiper from 'modules/shared/components/Swiper';
import { useToggle } from 'modules/shared/hooks/base';

import EditingWrapper from 'modules/shared/components/Grid/wrappers/EditingWrapper';
import ToolbarWrapper from 'modules/shared/components/Grid/wrappers/ToolbarWrapper';
import Grid from 'modules/shared/components/Grid';

import { DataStoresState } from '../context/DataStoresState';

import { useLoadSyncPathes } from '../hooks/useLoadSyncPathes';
import { useSyncPathActions } from '../hooks/useSyncPathActions';
import FileExplorer from './FileExplorer';
import SyncPathSchedulerModal from '../sync-path-scheduler-modal-ui';
import { SyncPathInput } from '../types';
import { DataStoreType } from '../contants';

const DataGrid = ToolbarWrapper(EditingWrapper(Grid));

const FileManagerUi = ({ renderButtons, readOnly }) => {
  const { connection, projectId, dataStoreConnection, dataStoreConnections } = useContext(DataStoresState);

  const { pathes } = useLoadSyncPathes({ connection, dataStoreConnection, projectId, type: 'import' });
  const { remove, create, creating, updateScheduler, updatingScheduler, resetScheduler, resettingScheduler } =
    useSyncPathActions({
      connection,
      projectId,
      type: 'import',
    });

  const onDeleteRow = useCallback(
    (row) => {
      remove(row.data.pathId);
    },
    [remove],
  );

  const p = useMemo(() => JSON.parse(JSON.stringify(pathes)), [pathes]);

  const [isSchedulerModalOpen, { deactivate: close, activate: open }] = useToggle(false);
  const [editingPathId, setEditingPathId] = useState('');
  const tempPathes = useRef<SyncPathInput[]>([]);

  useEffect(() => {
    if (!isSchedulerModalOpen) {
      tempPathes.current = [];
    }
  }, [isSchedulerModalOpen]);

  const initialValues = useMemo(() => {
    if (editingPathId) {
      const path = pathes.find((cur) => cur.pathId === editingPathId);

      return {
        name: path?.name,
        filter: path?.filter,
        unzip: path?.unzip,
        loadDeltaLake: path?.loadDeltaLake,
        scheduler: path?.scheduler.type || '',
        schedulerValue: path?.scheduler.pattern.split(' ') || null,
        schedulerLabel: path?.scheduler.label || '',
      };
    }

    const name = tempPathes?.current?.[0]?.externalPath ?? '';
    return {
      name,
      filter: '',
      unzip: false,
      loadDeltaLake: true,
      scheduler: '',
      schedulerValue: '',
      schedulerLabel: '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingPathId, pathes, isSchedulerModalOpen]);
  const closeModal = useCallback(() => {
    setEditingPathId('');
    close();
  }, [close]);

  const c = useMemo(
    () => dataStoreConnections.find((it) => it.connectionId === dataStoreConnection),
    [dataStoreConnections, dataStoreConnection],
  );

  const datagrid: Ref<DataGridType> = useRef<DataGridType>(null);

  useEffect(() => {
    if (resettingScheduler) {
      datagrid.current?.instance.beginCustomLoading('Resetting scheduler');
    }
  }, [resettingScheduler]);

  if (!dataStoreConnection) {
    return null;
  }

  const fileManagerComponent = (
    <>
      <FileExplorer
        connection={connection}
        projectId={projectId}
        dataStoreConnection={dataStoreConnection}
        creating={creating}
        readOnly={readOnly}
        onCreate={(items) => {
          return create(() => null, dataStoreConnection, items);
        }}
      />
    </>
  );

  const table = (
    <div
      style={{
        flexGrow: 1,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <DataGrid
          key={`${connection}-${projectId}`}
          dataSource={p}
          ref={datagrid}
          gridOptions={{
            editing: {
              allowUpdating: !readOnly,
              allowDeleting: !readOnly,
              confirmDelete: true,
            },
            height: '100%',
            onRowRemoved: onDeleteRow,
            onRowPrepared: (e) => {
              if (e.rowType === 'data' && e.rowElement) {
                if (e.data.scheduler.isRunning) {
                  e.rowElement.style.backgroundColor = 'rgba(237, 237, 0, 0.3)';
                  return;
                }

                if (!e.data.scheduler.pattern) {
                  e.rowElement.style.backgroundColor = 'rgba(85, 227, 14, 0.3)';
                  return;
                }

                if (!e.data.scheduler.lastExecTime) {
                  e.rowElement.style.backgroundColor = 'rgba(237, 28, 0, 0.3)';
                  return;
                }
                const timezoneOffset = new Date().getTimezoneOffset() * -1;

                const prevExecution = addMinutes(
                  parseExpression(e.data.scheduler.pattern).prev().toDate(),
                  timezoneOffset,
                );
                const execAt = new Date(e.data.scheduler.lastExecTime);

                if (prevExecution < execAt) {
                  e.rowElement.style.backgroundColor = 'rgba(85, 227, 14, 0.3)';
                } else {
                  e.rowElement.style.backgroundColor = 'rgba(237, 28, 0, 0.3)';
                }
              }
            },
          }}
          columns={[
            {
              dataField: 'name',
              caption: 'Name',
              minWidth: 120,
            },
            {
              dataField: 'path',
              caption: 'Path',
              minWidth: 120,
            },
            {
              dataField: 'hdfsLocation',
              caption: 'Storage Path',
            },
            {
              dataField: 'scheduler.label',
              caption: 'Scheduler',
              alignment: 'center',
              minWidth: 120,
            },
            { dataField: 'isFile', caption: 'File', width: 100, alignment: 'center' },
            { dataField: 'unzip', caption: 'Unzip', width: 100, alignment: 'center' },
            { dataField: 'loadDeltaLake', caption: 'Load Delta Lake', width: 100, alignment: 'center' },
            { dataField: 'filter', caption: 'Filter', width: 100, alignment: 'center' },
            {
              dataField: 'scheduler.isRunning',
              caption: 'Is Running',
              width: 100,
              alignment: 'center',
              cellRender: ({ data }) => {
                return data.scheduler.isRunning ? 'Yes' : 'No';
              },
              calculateGroupValue: (data) => {
                if (data.scheduler.isRunning) {
                  return 'Running';
                }

                return 'Not Running';
              },
              calculateCellValue: (data) => {
                if (data.scheduler.isRunning) {
                  return 'Running';
                }

                return 'Not Running';
              },
            },
            {
              dataField: 'scheduler.lastExecTime',
              dataType: 'datetime',
              caption: 'Last Execution Time',
              width: 100,
              alignment: 'center',
            },
            ...(!readOnly
              ? [
                  {
                    component: (
                      <Column key="buttons" type="buttons" caption="Actions">
                        <Button
                          name="edit"
                          onClick={(args) => {
                            setEditingPathId(args.row.data.pathId);
                            open();
                          }}
                        />
                        <Button
                          onClick={(args) => {
                            confirm('Are you sure you want to reset it?', 'Reset sync path').then((res) => {
                              if (res) {
                                resetScheduler(() => {
                                  datagrid.current?.instance.endCustomLoading();
                                }, args.row.data.pathId);
                              }
                            });
                          }}
                          icon="revert"
                        />
                        <Button name="delete" />
                      </Column>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </div>
    </div>
  );

  return (
    <>
      <Swiper
        renderButtons={renderButtons}
        items={[
          {
            title: 'File Manager',
            component: fileManagerComponent,
          },
          {
            title: 'Uploads',
            component: table,
          },
        ]}
      />
      <SyncPathSchedulerModal
        isOpen={isSchedulerModalOpen}
        close={closeModal}
        customFields={{ unzip: true, filter: c?.type === DataStoreType.Sharepoint, loadDeltaLake: true }}
        initialValues={initialValues}
        executing={creating || updatingScheduler}
        onSubmit={(name, custom, scheduler) => {
          return updateScheduler(closeModal, dataStoreConnection, editingPathId, name, custom, scheduler);
        }}
      />
    </>
  );
};

export default memo(FileManagerUi);
