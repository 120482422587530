import { Shape } from 'modules/shared/components/DraggableLayout';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash.clonedeep';

import { Step } from '../../types';
import { StepType, OUTPUT } from '../../constants';
import { generateEndConnectionId, generateStartConnectionId, getDefaultSourceShape } from '../../tools';

const generateHex = () => {
  return `#${Math.random().toString(16).substring(2, 8)}`;
};

export const handleShapeUpdate = (setSteps, selectedStep) => {
  return (id: string, shape: Partial<Shape>) => {
    setSteps((steps) =>
      steps.map((step) => {
        if (step.id === selectedStep) {
          return {
            ...step,
            config: {
              ...step.config,
              board: {
                ...step.config.board,
                shapes: step.config.board.shapes.map((existingShape) =>
                  existingShape.id === id ? { ...existingShape, ...shape } : existingShape,
                ),
              },
            },
          };
        }
        return step;
      }),
    );
  };
};

export const handleSetupInputShape = (setSteps, selectedStep, resolvers, setShouldCreateCombineMappings) => {
  return async (id: string, shape: Partial<Shape>): Promise<void> => {
    let promise = Promise.resolve();

    setSteps((steps) =>
      steps.map((step) => {
        if (step.id === selectedStep) {
          const clone: Step = cloneDeep(step);

          if ([StepType.TRANSFORM, StepType.BLEND].includes(clone.type)) {
            const outputShape = clone.config.board.shapes.find((it) => it.type === OUTPUT)!;
            const outputShapeCols: string[] = outputShape.metadata.columns.map((it) => it.name);
            const defaultValues = {};

            const connectionsToCreate = (shape.metadata?.columns || []).reduce((acc, col: string) => {
              const existing = outputShapeCols.find((name) => name.toLowerCase() === col.toLowerCase());
              if (existing) {
                defaultValues[existing] = '';
                return acc.concat({ from: col, to: existing });
              }
              return acc;
            }, [] as { from: string; to: string }[]);

            clone.config.board.shapes = clone.config.board.shapes.map((it) => {
              if (it.type === OUTPUT) {
                return {
                  ...it,
                  metadata: {
                    ...it.metadata,
                    defaultValues,
                  },
                };
              }
              return it;
            });

            clone.config.board.connections = connectionsToCreate.map((it) => ({
              connectionId: uuid(),
              start: generateStartConnectionId(id, it.from),
              end: generateEndConnectionId(outputShape.id, it.to),
              headSize: 4,
              tailSize: 4,
              curveness: 0.5,
              color: generateHex(),
              endPosition: ['left', 'right'],
              startPosition: ['left', 'right'],
              uniqKey: Math.random(),
              metadata: {
                hideArrows: false,
              },
            }));
          }

          clone.config.board.shapes = clone.config.board.shapes.map((cs) => {
            if (cs.id === id) {
              return {
                ...cs,
                ...shape,
                metadata: {
                  ...shape.metadata,
                },
              };
            }
            return cs;
          });

          if (step.type === StepType.MERGE) {
            clone.config.board.shapes = clone.config.board.shapes.map((cs) => {
              if (cs.id === id) {
                return {
                  ...cs,
                  ...shape,
                  metadata: {
                    ...shape.metadata,
                    mergeColumns: shape.metadata?.mergeColumns || [],
                  },
                };
              }
              return cs;
            });
          }
          if (step.type === StepType.EXPLODE) {
            clone.config.board.shapes = clone.config.board.shapes.map((cs) => {
              if (cs.id === id) {
                return {
                  ...cs,
                  ...shape,
                  metadata: {
                    ...shape.metadata,
                    explodeConfig: shape.metadata?.explodeConfig || {
                      exclude: [],
                      keep: [],
                      columnName: '',
                      useColumnValue: true,
                    },
                  },
                };
              }
              return cs;
            });
          }

          if (step.type === StepType.COMBINE) {
            promise = new Promise((resolve) => {
              resolvers.current.resolveInput = resolve;
            });
            setShouldCreateCombineMappings(true);
          }

          return clone;
        }
        return step;
      }),
    );

    await promise;
  };
};

export const handleRefreshCard = (steps, setSteps, load, handleDeleteConnectionChainHandler) => {
  return async (stepId: string, id: string) => {
    const step = steps.find((s) => s.id === stepId);

    if (!step) {
      return;
    }

    const shape = step.config.board.shapes.find((s) => s.id === id);
    if (!shape) return step;
    const { category, template, item } = shape.metadata;
    const newColumns = await load(category, template, item);

    const existingColumns = shape.metadata?.columns || [];
    const addedColumns = newColumns.filter((newCol) => !existingColumns.includes(newCol));
    const removedColumns = existingColumns.filter((oldCol) => !newColumns.includes(oldCol));

    const preservedConnections = step.config.board.connections.filter((conn) => {
      const startColumn = conn.start.split('-').pop();
      const endColumn = conn.end.split('-').pop();
      return !removedColumns.includes(startColumn) && !removedColumns.includes(endColumn);
    });

    const existingDefaultValues = shape.metadata.defaultValues || {};
    const updatedDefaultValues = Object.keys(existingDefaultValues)
      .filter((key) => !removedColumns.includes(key))
      .reduce((acc, key) => {
        acc[key] = existingDefaultValues[key];
        return acc;
      }, {});

    removedColumns.forEach((removedColumn) => {
      step.config.board.connections
        .filter((conn) => {
          const startColumn = conn.start.split('-').pop();
          const endColumn = conn.end.split('-').pop();
          return startColumn === removedColumn || endColumn === removedColumn;
        })
        .forEach((conn) => {
          handleDeleteConnectionChainHandler(conn.connectionId);
        });
    });

    const updatedColumns = [...existingColumns.filter((col) => !removedColumns.includes(col)), ...addedColumns];

    setSteps((s) =>
      s.map((innerStep) => {
        if (innerStep.id === step.id) {
          return {
            ...innerStep,
            config: {
              ...innerStep.config,
              board: {
                ...innerStep.config.board,
                shapes: innerStep.config.board.shapes.map((innerShape) =>
                  innerShape.id === id
                    ? {
                        ...innerShape,
                        metadata: {
                          ...innerShape.metadata,
                          columns: updatedColumns,
                          defaultValues: updatedDefaultValues,
                        },
                      }
                    : innerShape,
                ),
                connections: preservedConnections,
              },
            },
          };
        }
        return innerStep;
      }),
    );
  };
};

export const handleResetInputCard = (setSteps, selectedStep) => {
  return () => {
    setSteps((ps) =>
      ps.map((step) => {
        if (step.id === selectedStep) {
          const clone: Step = cloneDeep(step);

          const outputShape = clone.config.board.shapes.find((s) => s.type === OUTPUT)!;

          if (step.type === StepType.MERGE) {
            clone.config.board.shapes = [
              outputShape,
              {
                ...getDefaultSourceShape(),
                metadata: {
                  ...getDefaultSourceShape().metadata,
                  mergeColumns: [],
                },
              },
            ];
          } else {
            clone.config.board.connections = [];
            clone.config.board.shapes = [outputShape, getDefaultSourceShape()];
          }

          return clone;
        }
        return step;
      }),
    );
  };
};
