import { FileManagerComponent } from '@syncfusion/ej2-react-filemanager';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FileManager from 'modules/shared/components/FileManager';
import { Box, Button, CircularProgress } from '@mui/material';
// import ModalContent from 'modules/data-stores-custom/sync-path-scheduler-modal-ui/ModalContent';
// import { DataStoreType } from 'modules/data-stores-custom/contants';
import { DataStoresState } from 'modules/data-stores-custom/context/DataStoresState';
import SyncPathEditItem from 'modules/shared/components/SyncPathEditItem';
import { DataStoreType } from 'modules/data-stores-custom/contants';
import { useStyles } from './styles';

const MODAL_ID = 'custom-connections-upload-folder-explorer-modal';
const FORM_ID = 'custom-connections-upload-folder-explorer-modal-form';

const INITIAL_VALUES = {
  filter: '',
  unzip: false,
  loadDeltaLake: true,
  name: '',
  scheduler: '',
  schedulerValue: null,
  schedulerLabel: '',
};

enum Steps {
  FIRST = 'first',
  SECOND = 'second',
}

type Item = {
  isFile: boolean;
  name: string;
  scheduler: string;
  schedulerValue: string[] | null;
  schedulerLabel: string;
  filter: string;
  unzip: boolean;
  loadDeltaLake: boolean;
  externalPath: string;
};

const UploadFolderExplorer = ({ isOpen, onClose, projectId, connection, executing, onCreate, getItems }) => {
  const classes = useStyles();
  const { dataStoreConnection, dataStoreConnections } = useContext(DataStoresState);
  const [step, setStep] = useState<Steps>(Steps.FIRST);
  const [path, setPath] = useState('/');
  const [items, setItems] = useState<Array<Item>>([]);
  const opened = useRef(false);

  const fileManager = useRef<FileManagerComponent | null>(null);

  useEffect(() => {
    if (!isOpen) {
      opened.current = false;
      setStep(Steps.FIRST);
      setPath('/');
      setItems([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !opened.current) {
      opened.current = true;
      const selectedPathes = getItems().map((it) => ({
        externalPath: it.externalPath,
        isFile: it.isFile,
        ...INITIAL_VALUES,
      }));
      setItems(selectedPathes);
    }
  }, [isOpen, getItems]);

  const c = useMemo(
    () => dataStoreConnections.find((it) => it.connectionId === dataStoreConnection),
    [dataStoreConnections, dataStoreConnection],
  );

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={onClose}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={onClose}>
        {step === Steps.FIRST && 'Select folder'}
        {step === Steps.SECOND && 'Configure Sync'}
      </Title>
      <Content className={classes.dialogContent}>
        {step === Steps.FIRST && isOpen && (
          <div style={{ flex: 1, display: 'flex' }}>
            <FileManager
              id="hdfs-simple"
              path="/hdfs-access"
              allowFolderCreate
              key={`${projectId}-${connection}`}
              ref={fileManager}
              beforeSendBody={{
                connection,
                projectId,
                skipFiles: true,
              }}
            />
          </div>
        )}
        {step === Steps.SECOND && (
          <Box
            component="form"
            id={FORM_ID}
            onSubmit={(e) => {
              e.preventDefault();

              onCreate(
                items.map((it) => ({
                  name: it.name,
                  filter: it.filter,
                  unzip: it.unzip,
                  loadDeltaLake: it.loadDeltaLake,
                  hdfsPath: path,
                  isFile: it.isFile,
                  scheduler: it.scheduler,
                  schedulerLabel: it.schedulerLabel,
                  externalPath: it.externalPath,
                  schedulerPattern: it.schedulerValue ? it.schedulerValue.join(' ') : '',
                })),
              );
            }}
          >
            {items.map((item) => {
              return (
                <SyncPathEditItem
                  key={item.externalPath}
                  itemName={item.externalPath}
                  item={item}
                  onChange={(nextItem) => {
                    const newItems = items.map((i) => (i.externalPath === nextItem.externalPath ? nextItem : i));
                    setItems(newItems);
                  }}
                  customFields={{ unzip: true, filter: c?.type === DataStoreType.Sharepoint, loadDeltaLake: true }}
                  disabled={executing}
                />
              );
            })}
          </Box>
        )}
      </Content>
      {step === Steps.FIRST && (
        <Actions style={{ justifyContent: 'space-between' }}>
          <div className={classes.remarks}>* Your files will be uploaded in current folder</div>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              setPath(`/${fileManager.current!.pathNames.slice(1).join('/')}`);
              setStep(Steps.SECOND);
            }}
          >
            Next
          </Button>
        </Actions>
      )}
      {step === Steps.SECOND && (
        <Actions>
          <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={executing}>
            {executing ? <CircularProgress className={classes.progress} size={24} /> : 'Submit'}
          </Button>
        </Actions>
      )}
    </Dialog>
  );
};

export default memo(UploadFolderExplorer);
