import { Field } from 'modules/digital-threads/hooks';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import {
  ITEM_HEIGHT,
  MIN_CARD_HEIGHT,
  PADDING_TOP,
} from 'modules/shared/components/DraggableShapes/DigitalthreadsWizard/SourceDtData';
import { Connection, Shape } from 'modules/shared/components/DraggableLayout';
import {
  COMBINE_COLUMN,
  EDITABLE_COLUMNS,
  endConnectionDelimeter,
  INPUT,
  INPUT_SHAPE_HEIGHT,
  INPUT_SHAPE_WIDTH,
  OUTPUT,
  startConnectionDelimeter,
  StepType,
  TRANSFORMATION,
  CONDITION_CARD,
} from '../constants';
import { Step } from '../types';
import { processInputShape } from './process-steps.tools';

export const generateStartConnectionId = (id: string, title: string) => `${id}${startConnectionDelimeter}${title}`;
export const generateEndConnectionId = (id: string, title: string) => `${id}${endConnectionDelimeter}${title}`;

export const getDefaultSourceShape = () => ({
  id: uuid(),
  height: INPUT_SHAPE_HEIGHT,
  width: INPUT_SHAPE_WIDTH,
  metadata: {},
  title: 'Source',
  type: INPUT,
  xCoordinate: 360,
  yCoordinate: 360,
});

const getBaseShape = (type, title) => ({
  id: uuid(),
  height: 300,
  width: 250,
  metadata: {},
  title,
  type,
  xCoordinate: 440,
  yCoordinate: 360,
});

const getBaseOutputShape = () => getBaseShape(OUTPUT, 'Output');

export const getDefaultShapesByType = (
  type: StepType,
  templateId: string,
  targetFields: Field[],
  sourceFields: Field[],
): Shape[] => {
  const fieldNames = sourceFields.map((field) => field.name);
  if (type === StepType.EXPAND) {
    return [
      {
        ...getDefaultSourceShape(),
        title: templateId,
        metadata: {
          loading: false,
          columns: fieldNames,
          dataTemplate: templateId,
        },
      },
    ];
  }

  if (type === StepType.GROUP) {
    return [
      {
        ...getDefaultSourceShape(),
        title: templateId,
        metadata: {
          loading: false,
          columns: fieldNames,
          dataTemplate: templateId,
        },
      },
    ];
  }

  if (type === StepType.MERGE) {
    return [
      {
        ...getDefaultSourceShape(),
        metadata: {
          loading: false,
          columns: fieldNames,
          dataTemplate: templateId,
        },
      },
    ];
  }

  if (type === StepType.EXPLODE) {
    return [
      {
        ...getDefaultSourceShape(),
        title: templateId,
        metadata: {
          loading: false,
          columns: fieldNames,
          dataTemplate: templateId,
        },
      },
    ];
  }

  if ([StepType.BLEND, StepType.COMBINE].includes(type)) {
    return [
      getDefaultSourceShape(),
      {
        ...getBaseOutputShape(),
        title: templateId,
        height: PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * targetFields.length,
        metadata: {
          loading: false,
          columns: targetFields,
          dataTemplate: templateId,
        },
      },
    ];
  }

  if ([StepType.TRANSFORM].includes(type)) {
    return [
      {
        ...getDefaultSourceShape(),
        title: templateId,
        metadata: {
          loading: false,
          columns: fieldNames,
          dataTemplate: templateId,
        },
      },
      {
        ...getBaseOutputShape(),
        title: templateId,
        height: PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * targetFields.length,
        metadata: {
          loading: false,
          columns: targetFields,
          dataTemplate: templateId,
        },
      },
    ];
  }

  if (StepType.ADD_COLUMNS === type) {
    return [
      {
        ...getBaseShape(EDITABLE_COLUMNS, 'Add'),
        title: templateId,
        height: PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * targetFields.length,
        metadata: {
          loading: false,
          columns: targetFields,
          dataTemplate: templateId,
        },
      },
    ];
  }

  if (StepType.DELETE_COLUMNS === type) {
    return [
      {
        ...getBaseShape(EDITABLE_COLUMNS, 'Delete'),
        title: templateId,
        height: PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * targetFields.length,
        metadata: {
          loading: false,
          columns: targetFields,
          dataTemplate: templateId,
        },
      },
    ];
  }

  return [];
};

export const getAdditionalConfigByType = (type: StepType) => {
  if (type === StepType.BLEND) {
    return {
      blendConfig: {
        processMatchMany: false,
        returnNoMatch: false,
        matchingRules: [{ source: '', target: '' }],
      },
    };
  }

  if (type === StepType.ADD_COLUMNS) {
    return {
      addColumns: [],
    };
  }

  if (type === StepType.DELETE_COLUMNS) {
    return {
      deleteColumns: [],
    };
  }

  if (type === StepType.MERGE) {
    return {
      mergeColumns: [],
    };
  }

  if (type === StepType.EXPLODE) {
    return {
      exclude: [],
      useColumnValue: true,
      keep: [],
      columnName: '',
    };
  }

  if (type === StepType.GROUP) {
    return {
      groupConfig: {
        caseSensitive: false,
        keys: [],
        values: [],
        rules: [],
      },
    };
  }

  if (type === StepType.EXPAND) {
    return {
      expandConfig: {
        keep: [],
        columnDelimiter: '',
        columnName: '',
      },
    };
  }

  return {};
};

export const getBaseFieldsBasedOnSteps = (baseFields: Field[], steps: Step[], stepIndex: number) => {
  const prevSteps = steps.slice(0, stepIndex);
  let fields = baseFields.slice(0);

  prevSteps.forEach((step) => {
    if (step.type === StepType.TRANSFORM) {
      const inputShape: Shape = step.config.board.shapes.find((s) => s.type === INPUT)!;
      const transformConfig = processInputShape(inputShape, step.config.board.shapes, step.config.board.connections);

      const mappedCols = transformConfig.mappings.map((m) => m.target);

      fields = fields.map((f) => ({
        ...f,
        isMapped: mappedCols.includes(f.name),
      }));
    }

    if (step.type === StepType.COMBINE) {
      const combineColShapes = step.config.board.shapes.filter((s) => s.type === COMBINE_COLUMN);

      fields = combineColShapes.map((it) => ({
        name: it.title,
        description: it.title,
        required: true,
        type: 'text',
      }));
    }

    if (step.type === StepType.ADD_COLUMNS) {
      step.config.addColumns?.forEach((col) => {
        fields.push({
          name: col.colName,
          description: col.colName,
          required: true,
          type: 'text',
        });
      });
    }

    if (step.type === StepType.DELETE_COLUMNS) {
      step.config.deleteColumns?.forEach((col) => {
        fields = fields.filter((f) => f.name !== col);
      });
    }
  });

  return fields;
};

export const deleteConnectionChainFromStep = (step: Step, connectionId: string): Step => {
  const { shapes, connections } = step.config.board;

  const currentConnection = connections.find((c) => c.connectionId === connectionId)!;

  const goToStart = (c: Connection) => {
    const startShape = shapes.find((s) => s.id === c.start);

    if ([TRANSFORMATION, COMBINE_COLUMN, CONDITION_CARD].includes(startShape?.type || '')) {
      const { id } = startShape!;
      const endConnection = connections.find((c1) => c1.end === id)!;
      const result = goToStart(endConnection);

      return [id, ...result];
    }

    return [];
  };

  const goToEnd = (c: Connection) => {
    const endShape = shapes.find((s) => s.id === c.end);

    if ([TRANSFORMATION, COMBINE_COLUMN, CONDITION_CARD].includes(endShape?.type || '')) {
      const { id } = endShape!;
      const endConnection = connections.find((c1) => c1.start === id)!;
      const result = goToEnd(endConnection);

      return [id, ...result];
    }

    return [];
  };

  const result = [...goToStart(currentConnection), ...goToEnd(currentConnection)];

  step.config.board.shapes = shapes.filter((s) => !result.includes(s.id));

  step.config.board.connections = connections.filter((c) => {
    if (c.connectionId === connectionId) {
      return false;
    }
    if (result.includes(c.start)) {
      return false;
    }
    if (result.includes(c.end)) {
      return false;
    }

    return true;
  });

  return step;
};

export const getSourceFieldsBasedOnSteps = (steps: Step[], stepIndex: number): Field[] => {
  const prevSteps = steps.slice(0, stepIndex);
  let sourceFields: Field[] = [];
  let primarySourceFields: Field[] = [];

  prevSteps.forEach((step) => {
    const inputShape = step.config.board.shapes.find((s) => s.type === INPUT);

    if (inputShape && inputShape.metadata && Array.isArray(inputShape.metadata.columns)) {
      const newSourceFields = inputShape.metadata.columns
        .filter((col) => col && col.name)
        .map((col) => ({
          name: col,
          description: col,
          required: true,
          type: 'text',
        }));

      if (primarySourceFields.length === 0) {
        primarySourceFields = [...newSourceFields];
      }
      sourceFields = [...newSourceFields];
    }

    if (step.type === StepType.TRANSFORM && inputShape) {
      const transformFields = inputShape.metadata.columns.map((col) => ({
        name: col,
        description: col,
        required: true,
        type: 'text',
      }));
      primarySourceFields = [...transformFields];
      sourceFields = [...transformFields];
    }

    if (step.type === StepType.MERGE && inputShape) {
      const mergedFields = inputShape.metadata.columns.map((col) => ({
        name: col,
        description: col,
        required: true,
        type: 'text',
      }));
      primarySourceFields = [...mergedFields];
      sourceFields = [...mergedFields];
    }

    if (step.type === StepType.EXPLODE) {
      const { exclude = [], keep = [], columnName = '' } = step.config.explodeConfig || {};
      sourceFields = sourceFields.filter((f) => !exclude.includes(f.name));

      keep.forEach((col) => {
        if (!sourceFields.find((f) => f.name === col)) {
          sourceFields.push({
            name: col,
            description: col,
            required: true,
            type: 'text',
          });
        }
      });

      if (columnName && !sourceFields.find((f) => f.name === columnName)) {
        sourceFields.push({
          name: columnName,
          description: columnName,
          required: true,
          type: 'text',
        });
      }
      primarySourceFields = [...sourceFields];
    }

    if (step.type === StepType.GROUP) {
      const { keys = [], values = [] } = step.config.groupConfig || {};

      keys.forEach((key) => {
        if (!sourceFields.find((f) => f.name === key)) {
          sourceFields.push({
            name: key,
            description: key,
            required: true,
            type: 'text',
          });
        }
      });

      values.forEach((value) => {
        if (!sourceFields.find((f) => f.name === value)) {
          sourceFields.push({
            name: value,
            description: value,
            required: true,
            type: 'text',
          });
        }
      });
      primarySourceFields = [...sourceFields];
    }

    if (step.type === StepType.EXPAND) {
      const { keep = [], columnName = '' } = step.config.expandConfig || {};

      sourceFields = primarySourceFields.filter((f) => keep.includes(f.name) || f.name === columnName);

      keep.forEach((col) => {
        if (!sourceFields.find((f) => f.name === col)) {
          sourceFields.push({
            name: col,
            description: col,
            required: true,
            type: 'text',
          });
        }
      });

      if (columnName && !sourceFields.find((f) => f.name === columnName)) {
        sourceFields.push({
          name: columnName,
          description: columnName,
          required: true,
          type: 'text',
        });
      }
      primarySourceFields = [...sourceFields];
    }

    if (step.type === StepType.BLEND || step.type === StepType.COMBINE) {
      sourceFields = [...primarySourceFields];
    }
  });

  return primarySourceFields.length > 0 ? primarySourceFields : sourceFields;
};

export const normalizeSteps = (steps: Step[], baseFields: Field[]): Step[] => {
  const clone: Step[] = cloneDeep(steps);

  for (let i = 0; i < clone.length; i++) {
    const prevStepFields = getBaseFieldsBasedOnSteps(baseFields, clone, i);

    const currentStep = clone[i];

    if (currentStep.type === StepType.DELETE_COLUMNS) {
      const shapeIdx = currentStep.config.board.shapes.findIndex((s) => s.type === EDITABLE_COLUMNS);

      currentStep.config.board.shapes[shapeIdx].metadata.columns = prevStepFields;
      currentStep.config.board.shapes[shapeIdx].height =
        PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * prevStepFields.length;
      currentStep.config.deleteColumns = currentStep.config.deleteColumns?.filter(
        (col) => !!prevStepFields.find((f) => f.name === col),
      );
    }

    if (currentStep.type === StepType.ADD_COLUMNS) {
      const shapeIdx = currentStep.config.board.shapes.findIndex((s) => s.type === EDITABLE_COLUMNS);

      currentStep.config.board.shapes[shapeIdx].metadata.columns = prevStepFields;
      currentStep.config.board.shapes[shapeIdx].height =
        PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * prevStepFields.length;

      currentStep.config.addColumns = currentStep.config.addColumns?.filter(
        (col) => !prevStepFields.find((f) => f.name === col.colName),
      );
    }

    if ([StepType.TRANSFORM, StepType.BLEND, StepType.COMBINE].includes(currentStep.type)) {
      const shapeIdx = currentStep.config.board.shapes.findIndex((s) => s.type === OUTPUT);

      const shape = currentStep.config.board.shapes[shapeIdx]!;

      const prevCols = shape.metadata.columns;

      const deletedCols = prevCols.filter((col) => !prevStepFields.find((f) => f.name === col.name)).map((c) => c.name);
      currentStep.config.board.shapes[shapeIdx]!.metadata.columns = prevStepFields;
      currentStep.config.board.shapes[shapeIdx].height =
        PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * prevStepFields.length;

      deletedCols.forEach((col) => {
        const endConnectionId = generateEndConnectionId(shape.id, col);
        const connection = currentStep.config.board.connections.find((c) => c.end === endConnectionId);
        if (connection) {
          clone[i] = deleteConnectionChainFromStep(currentStep, connection.connectionId);
        }
      });

      if (currentStep.type === StepType.BLEND) {
        currentStep.config.blendConfig!.matchingRules = currentStep.config.blendConfig!.matchingRules.map((r) => {
          return {
            source: deletedCols.includes(r.source) ? '' : r.source,
            target: deletedCols.includes(r.target) ? '' : r.target,
          };
        });
      }
    }
  }

  return clone;
};

export const getStepError = (step: Step): string => {
  if (step.type === StepType.BLEND) {
    const { matchingRules } = step.config.blendConfig!;
    if (matchingRules.length === 1 && !matchingRules[0].source && !matchingRules[0].target) {
      return 'Provide atleast one matching rule';
    }

    if (!matchingRules.every((r) => r.source && r.target)) {
      return 'All matching rules should have both source and target';
    }

    return '';
  }

  return '';
};

const getConfig = (steps: Step[], type: StepType) => {
  const stepsByType = steps.filter((s) => s.type === type);

  return stepsByType.map((step) => {
    const input = step?.config.board.shapes.find((s) => s.type === INPUT);

    return {
      dataGroup: input?.metadata.category || null,
      dataTable: input?.metadata.template || null,
    };
  });
};

export const processSteps = (steps: Step[]) => {
  const transformStep = steps.find((s) => s.type === StepType.TRANSFORM);
  if (!transformStep) {
    throw new Error('Transform step is required');
  }

  const firstInputStep = steps.find((s) => s.config.board.shapes.some((shape) => shape.type === INPUT));

  const inputShape = firstInputStep?.config.board.shapes.find((s) => s.type === INPUT);

  const config = {
    source: {
      Data_Group: inputShape?.metadata.category || null,
      Data_Table: inputShape?.metadata.template || null,
    },
    combine: getConfig(steps, StepType.COMBINE),
    blend: getConfig(steps, StepType.BLEND),
  };

  const stepsError = !steps.every((s) => !getStepError(s));

  if (stepsError) {
    throw new Error('Provide all required data for steps');
  }

  const mappedSteps = steps.reduce((acc, step) => {
    if (step.type === StepType.ADD_COLUMNS) {
      return acc.concat(
        (step.config.addColumns || []).map((col) => {
          let value = col.colType;

          if (col.isDate) {
            value = '<DATE>';
          } else if (col.isUuid) {
            value = '<UUID>';
          }
          return {
            ruleType: 'extend',
            payload: {
              propName: col.colName,
              propValue: value,
            },
          };
        }),
      );
    }

    if (step.type === StepType.DELETE_COLUMNS) {
      return acc.concat(
        (step.config.deleteColumns || []).map((col) => ({
          ruleType: 'remove',
          payload: {
            column: col,
          },
        })),
      );
    }

    if (step.type === StepType.TRANSFORM) {
      const transformInputShape = step.config.board.shapes.find((s) => s.type === INPUT)!;
      const outputShape: Shape = step.config.board.shapes.find((s) => s.type === OUTPUT)!;

      const transformConfig = processInputShape(
        transformInputShape,
        step.config.board.shapes,
        step.config.board.connections,
      );

      return acc.concat({
        ruleType: 'transform',
        payload: {
          mappings: transformConfig.mappings,
          targetColumns: outputShape.metadata.columns.map((c) => c.name),
        },
      });
    }

    if (step.type === StepType.BLEND) {
      const blendInputShape = step.config.board.shapes.find((s) => s.type === INPUT)!;

      const transformConfig = processInputShape(
        blendInputShape,
        step.config.board.shapes,
        step.config.board.connections,
      );

      return acc.concat({
        ruleType: 'blend',
        payload: {
          mappings: transformConfig.mappings,
          returnNoMatch: step.config.blendConfig!.returnNoMatch,
          processMatchMany: step.config.blendConfig!.processMatchMany,
          matchingRules: step.config.blendConfig!.matchingRules,
        },
      });
    }

    if (step.type === StepType.MERGE) {
      const mergeColumns = step.config.mergeColumns || [];

      if (mergeColumns.length === 0) {
        return acc.concat({
          ruleType: 'merge',
        });
      }

      const keyField = mergeColumns.map((col) => col.name).join('|');
      return acc.concat({
        ruleType: 'merge',
        keyField,
      });
    }

    if (step.type === StepType.EXPLODE) {
      const explodeConfig = step.config.explodeConfig || {
        exclude: [],
        useColumnValue: true,
        keep: [],
        columnName: '',
      };

      const orderedPayload = {
        exclude: explodeConfig.exclude,
        useColumnValue: explodeConfig.useColumnValue,
        keep: explodeConfig.keep,
        columnName: explodeConfig.columnName,
      };

      return acc.concat({
        ruleType: 'explode',
        payload: orderedPayload,
      });
    }

    if (step.type === StepType.GROUP) {
      const groupConfig = step.config.groupConfig || {
        caseSensitive: false,
        keys: [],
        values: [],
        rules: [],
      };

      const orderedPayload = {
        caseSensitive: groupConfig.caseSensitive,
        keys: groupConfig.keys,
        values: groupConfig.values,
        rules: groupConfig.rules,
      };

      return acc.concat({
        ruleType: 'grouping',
        payload: orderedPayload,
      });
    }

    if (step.type === StepType.EXPAND) {
      const expandConfig = step.config.expandConfig || {
        keep: [],
        columnDelimiter: '',
        columnName: '',
      };

      const orderedPayload = {
        keep: expandConfig.keep,
        columnDelimiter: expandConfig.columnDelimiter,
        columnName: expandConfig.columnName,
      };

      return acc.concat({
        ruleType: 'expand',
        payload: orderedPayload,
      });
    }

    if (step.type === StepType.COMBINE) {
      const combineShapes = step.config.board.shapes.filter((s) => s.type === COMBINE_COLUMN);

      const payload = combineShapes.reduce(
        (prev, cur) => {
          const { title, id } = cur;

          prev.columns.push(title);
          const connections = step.config.board.connections.filter((c) => c.end === id || c.start === id);
          connections.forEach((c) => {
            if (c.end === id) {
              prev.combine_mappings.push({ target: title, source: c.start.split(startConnectionDelimeter)[1] });
            }

            if (c.start === id) {
              prev.source_mappings.push({ target: title, source: c.end.split(endConnectionDelimeter)[1] });
            }
          });

          return prev;
        },
        {
          columns: [] as string[],
          source_mappings: [] as { target: string; source: string }[],
          combine_mappings: [] as { target: string; source: string }[],
        },
      );
      return acc.concat({
        ruleType: 'combine',
        payload,
      });
    }

    return acc;
  }, [] as Array<any>);

  return {
    config,
    mappings: {
      steps: mappedSteps,
    },
  };
};

export const getAvailableStepsToAdd = (steps: Step[]) => {
  const ableToAddTransform = !steps.find((s) => s.type === StepType.TRANSFORM);

  return Object.values(StepType).reduce((acc, type) => {
    if (type === StepType.TRANSFORM && !ableToAddTransform) {
      return acc;
    }

    return acc.concat(type);
  }, [] as StepType[]);
};
