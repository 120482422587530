import { useToggle } from 'modules/shared/hooks/base';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LayoutRef } from 'modules/shared/components/DraggableLayout';
import Storage from 'modules/storage';
import { useLoadDataTemplateFieldsDefinitions } from 'modules/digital-threads/hooks';
import { useToggleWithValue } from 'modules/shared/hooks/base/useToggleWithValue';
import { compareObjects } from 'modules/flow-control/utils';
import { noop } from 'modules/shared/utils';
import { useLoadFileColumns } from '../hooks/useLoadFileColumns';
import { WizardContext, WizardHandlersContext } from './context';
import { ConnectionPoint, DefaultValueConfig, Step } from '../types';
import { Views } from '../constants';
import { useLatestMappings } from '../hooks/useLatestMappings';
import { usePublishSteps } from '../hooks/usePublishSteps';
import { handleCreateStep, handleDeleteStep, handleSelectStep, handleUpdateStepConfig } from './handlers/stepHandlers';
import {
  handleShapeUpdate,
  handleSetupInputShape,
  handleRefreshCard,
  handleResetInputCard,
} from './handlers/shapeHandlers';
import { handleCreateConnection, handleDeleteConnectionChain } from './handlers/connectionHandlers';
import {
  handleAddCombineColumn,
  handleDeleteCombineColumn,
  handleCloseUpdateCombineModal,
  handleUpdateCombineColumn,
} from './handlers/combineColumnHandlers';
import { handleValidateDefaultValues, handleUpdateDefaultValue } from './handlers/defaultValueHandlers';
import {
  handleAddTransformation,
  handleDeleteTransformation,
  handleUpdateTransformation,
} from './handlers/transformationHandlers';
import { handleAddCondition, handleUpdateCondition, handleDeleteCondition } from './handlers/conditionHandlers';
import { useCombineMappings } from './hooks/useCombineMappings';

const getCurrentView = (boardConfig, config) => {
  if (boardConfig.steps?.length) {
    return Views.BOARD;
  }
  if (config?.mappings?.length) {
    return Views.JSON;
  }
  return Views.BOARD;
};

const WizardProvider = ({ children, templateId }) => {
  const { projectId, connection } = useProjectInfoFromSearch();
  const storageKey = `mappings-v2-${projectId}-${templateId}`;
  const { publish, publishing } = usePublishSteps({ connection, projectId });
  const { load } = useLoadFileColumns({ connection, projectId });

  const { loading: latestMappingsLoading, latestMappings } = useLatestMappings({
    connection,
    projectId,
    dataTemplate: templateId,
  });

  const latestMappingsBoardConfig = useMemo(() => {
    if (latestMappings?.boardConfig) {
      const board = JSON.parse(latestMappings.boardConfig);

      return board;
    }

    return {
      steps: [],
      selectStep: '',
    };
  }, [latestMappings]);

  const [view, setView] = useState<Views>(Views.BOARD);

  const { fields: baseFields, loading } = useLoadDataTemplateFieldsDefinitions(templateId, false, connection);

  const layoutRef = useRef<LayoutRef>({} as LayoutRef);
  const resolvers = useRef({
    resolveInput: noop,
  });
  const [shouldCreateCombineMappings, setShouldCreateCombineMappings] = useState(false);

  const {
    value: currentDefaultValueConfig,
    isOpen: isEditDefaultValueModalOpen,
    activate: openEditDefaultValueModal,
    deactivate: closeEditDefaultValueModal,
  } = useToggleWithValue<DefaultValueConfig>({ shapeId: '', col: '' });

  const [draft, setDraft] = useState<boolean>(false);
  const [selectedStep, setSelectedStep] = useState<string>('');
  const [steps, setSteps] = useState<Step[]>([]);

  const handleSetSelectedStep = useCallback(
    (step) => {
      closeEditDefaultValueModal();
      setSelectedStep(step);
    },
    [closeEditDefaultValueModal],
  );

  const [draggableItem, setDraggableItem] = useState<ConnectionPoint>({
    parentId: '',
    title: '',
  });

  const stepData = steps.find((s) => s.id === selectedStep);

  useEffect(() => {
    if (stepData) {
      layoutRef.current?.adaptLayout?.(stepData!.config.board);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep]);

  useEffect(() => {
    if (!latestMappingsLoading) {
      setSteps(latestMappingsBoardConfig.steps);
      handleSetSelectedStep(latestMappingsBoardConfig.selectedStep);
      setView(getCurrentView(latestMappingsBoardConfig, latestMappings));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, latestMappingsLoading]);

  const {
    value: editableCombineColumn,
    isOpen: isUpdateCombineColumnOpen,
    activate: handleOpenUpdateCombineColumn,
    deactivate: handleCloseUpdateCombineColumn,
  } = useToggleWithValue({ shapeId: '', forceDelete: false });

  const {
    value: selectedImportShape,
    isOpen: isImportSourceModalOpen,
    activate: openImportSource,
    deactivate: closeImportSource,
  } = useToggleWithValue('');

  const {
    value: editableTansformation,
    isOpen: isUpdateTransformationModalOpen,
    activate: handleOpenUpdateTransformation,
    deactivate: handleCloseUpdateTransformation,
  } = useToggleWithValue('');

  const {
    value: editableCondition,
    isOpen: isUpdateConditionModalOpen,
    activate: handleOpenUpdateCondition,
    deactivate: handleCloseUpdateCondition,
  } = useToggleWithValue('');

  const {
    value: currentAddColumn,
    isOpen: isAddColumnTypeModalOpen,
    activate: openAddColumnTypeModal,
    deactivate: closeAddColumnTypeModal,
  } = useToggleWithValue('');

  const {
    value: currentGroupColumn,
    isOpen: isGroupRuleModalOpen,
    activate: openGroupRuleModal,
    deactivate: closeGroupRuleModal,
  } = useToggleWithValue('');

  const createStepHandler = handleCreateStep(setSteps, handleSetSelectedStep, baseFields, templateId);
  const deleteStepHandler = handleDeleteStep(setSteps, selectedStep, handleSetSelectedStep, baseFields);
  const selectStepHandler = handleSelectStep(setSteps, handleSetSelectedStep, baseFields);
  const updateStepConfigHandler = handleUpdateStepConfig(setSteps, selectedStep);

  const handleShapeUpdateHandler = handleShapeUpdate(setSteps, selectedStep);
  const handleSetupInputShapeHandler = handleSetupInputShape(
    setSteps,
    selectedStep,
    resolvers,
    setShouldCreateCombineMappings,
  );

  const validateDefaultValuesHandler = handleValidateDefaultValues(setSteps, selectedStep);

  const handleDeleteConnectionChainHandler = handleDeleteConnectionChain(
    setSteps,
    selectedStep,
    validateDefaultValuesHandler,
  );

  const resetInputCardHandler = handleResetInputCard(setSteps, selectedStep);
  const handleRefreshCardHandler = handleRefreshCard(steps, setSteps, load, handleDeleteConnectionChainHandler);

  const addCombineColumnHandler = handleAddCombineColumn(
    setSteps,
    selectedStep,
    layoutRef,
    handleOpenUpdateCombineColumn,
  );
  const deleteCombineColumnHandler = handleDeleteCombineColumn(setSteps, selectedStep);
  const closeUpdateCombineModalHandler = handleCloseUpdateCombineModal(
    editableCombineColumn,
    deleteCombineColumnHandler,
    handleCloseUpdateCombineColumn,
  );

  const updateCombineColumnHandler = handleUpdateCombineColumn(setSteps, selectedStep);

  useCombineMappings(
    stepData,
    layoutRef,
    addCombineColumnHandler,
    shouldCreateCombineMappings,
    setShouldCreateCombineMappings,
    resolvers,
  );

  const updateDefaultValueHandler = handleUpdateDefaultValue(setSteps, selectedStep);
  const createConnectionHandler = handleCreateConnection(
    setSteps,
    selectedStep,
    stepData,
    layoutRef,
    addCombineColumnHandler,
    updateDefaultValueHandler,
  );

  const addTransformationHandler = handleAddTransformation(setSteps, selectedStep, layoutRef);
  const updateTransformationHandler = handleUpdateTransformation(setSteps, selectedStep);
  const deleteTransformationHandler = handleDeleteTransformation(setSteps, selectedStep);

  const addConditionHandler = handleAddCondition(setSteps, selectedStep, layoutRef);
  const updateConditionHandler = handleUpdateCondition(setSteps, selectedStep);
  const deleteConditionHandler = handleDeleteCondition(setSteps, selectedStep);

  const [isBlendConfigModalOpen, { activate: openBlendConfigModal, deactivate: closeBlendConfigModal }] =
    useToggle(false);

  useEffect(() => {
    const data = {
      steps,
      selectedStep,
    };

    if (!latestMappingsLoading) {
      const theSame = compareObjects(latestMappingsBoardConfig.steps, data.steps);
      setDraft(!theSame);

      if (templateId) {
        Storage.set(storageKey, JSON.stringify(data));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, selectedStep, latestMappingsLoading, latestMappingsBoardConfig]);

  return (
    <WizardHandlersContext.Provider
      value={useMemo(
        () => ({
          createStep: createStepHandler,
          deleteStep: deleteStepHandler,
          selectStep: selectStepHandler,
          setupInputShape: handleSetupInputShapeHandler,
          refreshCard: handleRefreshCardHandler,
          updateShape: handleShapeUpdateHandler,
          openImportSourceModal: openImportSource,
          closeImportSourceModal: closeImportSource,
          openUpdateTransformationModal: handleOpenUpdateTransformation,
          closeUpdateTransformationModal: handleCloseUpdateTransformation,
          openUpdateCombineModalModal: handleOpenUpdateCombineColumn,
          closeUpdateCombineModalModal: closeUpdateCombineModalHandler,
          updateDraggableItem: setDraggableItem,
          createConnection: createConnectionHandler,
          addTransformation: addTransformationHandler,
          resetInputCard: resetInputCardHandler,
          updateTransformation: updateTransformationHandler,
          deleteTransformation: deleteTransformationHandler,
          addCondition: addConditionHandler,
          updateCondition: updateConditionHandler,
          deleteCondition: deleteConditionHandler,
          openUpdateConditionModal: handleOpenUpdateCondition,
          closeUpdateConditionModal: handleCloseUpdateCondition,
          deleteConnectionChain: handleDeleteConnectionChainHandler,
          updateStepConfig: updateStepConfigHandler,
          updateDefaultValue: updateDefaultValueHandler,
          openBlendConfigModal,
          closeBlendConfigModal,
          openAddColumnTypeModal,
          closeAddColumnTypeModal,
          openGroupRuleModal,
          closeGroupRuleModal,
          resetState: () => {
            setSteps(latestMappingsBoardConfig?.steps || []);
            setSelectedStep(latestMappingsBoardConfig?.selectedStep || '');
            Storage.remove(storageKey);
          },
          setState: (nextSteps: Step[], nextSelectedStep: string) => {
            setSteps(nextSteps);
            setSelectedStep(nextSelectedStep);
          },
          openEditDefaultValueModal,
          closeEditDefaultValueModal,
          addCombineColumn: addCombineColumnHandler,
          updateCombineColumn: updateCombineColumnHandler,
          deleteCombineColumn: deleteCombineColumnHandler,
          changeView: setView,
          publish,
        }),
        [
          publish,
          addCombineColumnHandler,
          addConditionHandler,
          addTransformationHandler,
          closeAddColumnTypeModal,
          closeBlendConfigModal,
          closeEditDefaultValueModal,
          closeGroupRuleModal,
          closeImportSource,
          closeUpdateCombineModalHandler,
          createStepHandler,
          createConnectionHandler,
          deleteCombineColumnHandler,
          deleteConditionHandler,
          deleteTransformationHandler,
          deleteStepHandler,
          handleCloseUpdateTransformation,
          handleOpenUpdateCombineColumn,
          handleOpenUpdateTransformation,
          handleOpenUpdateCondition,
          handleCloseUpdateCondition,
          handleRefreshCardHandler,
          handleDeleteConnectionChainHandler,
          handleSetupInputShapeHandler,
          handleShapeUpdateHandler,
          latestMappingsBoardConfig?.selectedStep,
          latestMappingsBoardConfig?.steps,
          openAddColumnTypeModal,
          openBlendConfigModal,
          openEditDefaultValueModal,
          openGroupRuleModal,
          openImportSource,
          selectStepHandler,
          updateCombineColumnHandler,
          updateConditionHandler,
          updateDefaultValueHandler,
          updateStepConfigHandler,
          updateTransformationHandler,
          resetInputCardHandler,
          storageKey,
        ],
      )}
    >
      <WizardContext.Provider
        value={useMemo(
          () => ({
            currentDefaultValueConfig,
            isEditDefaultValueModalOpen,
            baseInfoLoading: loading || latestMappingsLoading,
            isAddColumnTypeModalOpen,
            isImportSourceModalOpen,
            layoutRef,
            projectId,
            connection,
            templateId,
            selectedStep,
            steps,
            stepData,
            selectedImportShape,
            isUpdateTransformationModalOpen,
            editableTansformation,
            draggableItem,
            isBlendConfigModalOpen,
            currentAddColumn,
            currentGroupColumn,
            isGroupRuleModalOpen,
            isDraft: draft,
            editableCombineColumn,
            isUpdateCombineColumnOpen,
            jsonConfigs: {
              config: latestMappings?.config,
              mappings: latestMappings?.mappings,
            },
            latestMappingsBoardConfig,
            view,
            publishing,
            isUpdateConditionModalOpen,
            editableCondition,
          }),
          [
            view,
            publishing,
            latestMappingsBoardConfig,
            latestMappings?.config,
            latestMappings?.mappings,
            connection,
            currentAddColumn,
            currentGroupColumn,
            currentDefaultValueConfig,
            draft,
            latestMappingsLoading,
            draggableItem,
            editableCombineColumn,
            editableTansformation,
            isAddColumnTypeModalOpen,
            isBlendConfigModalOpen,
            isEditDefaultValueModalOpen,
            isGroupRuleModalOpen,
            isImportSourceModalOpen,
            isUpdateCombineColumnOpen,
            isUpdateTransformationModalOpen,
            loading,
            projectId,
            selectedImportShape,
            selectedStep,
            stepData,
            steps,
            templateId,
            isUpdateConditionModalOpen,
            editableCondition,
          ],
        )}
      >
        {children}
      </WizardContext.Provider>
    </WizardHandlersContext.Provider>
  );
};

export default memo(WizardProvider);
