import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import Input from 'modules/shared/components/Input';
import { useStyles } from './styles';
import { COMBINE_COLUMN } from '../../constants';
import { WizardContext, WizardHandlersContext } from '../../wizard-context/context';

const MODAL_ID = 'update-transformation-modal';

const Modal = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { isUpdateCombineColumnOpen: isOpen, editableCombineColumn, stepData } = useContext(WizardContext);
  const { closeUpdateCombineModalModal, updateCombineColumn } = useContext(WizardHandlersContext);

  const [title, setTitle] = useState<string>('');

  const { shapes } = stepData!.config.board;

  const shape = useMemo(
    () => shapes.find((s) => s.id === editableCombineColumn.shapeId),
    [shapes, editableCombineColumn],
  );

  useEffect(() => {
    if (isOpen) {
      setTitle(shape?.title || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSubmit = () => {
    const titleToUse = title.trim().toLowerCase();

    const existingCols =
      stepData?.config.board.shapes.filter((it) => it.type === COMBINE_COLUMN).map((it) => it.title) || [];

    if (!titleToUse) {
      enqueueSnackbar('Title should not be empty', BASE_ERROR_NOTIFICATION_OPTIONS);
    } else if (existingCols.includes(titleToUse) && shape?.title !== titleToUse) {
      enqueueSnackbar('Such column is already exists', BASE_ERROR_NOTIFICATION_OPTIONS);
    } else {
      updateCombineColumn(editableCombineColumn.shapeId, titleToUse);

      closeUpdateCombineModalModal({ skipDelete: true });
    }
  };

  const handleClose = () => {
    closeUpdateCombineModalModal({ skipDelete: !editableCombineColumn.forceDelete });
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={() => handleClose()}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={handleClose}>
        Title for Combine column
      </Title>
      <Content className={classes.dialogContent}>
        <Input
          labelText="Title"
          labelProps={{
            shrink: true,
          }}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: title,
            required: true,
            onChange: (event) => {
              const { value } = event.target;

              setTitle(value);
            },
          }}
        />
      </Content>
      <Actions>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(Modal);
