import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { INPUT, OUTPUT } from 'modules/digital-threads-mapping-wizard/constants';
import { generateEndConnectionId, generateStartConnectionId } from '../../tools';

export const useCombineMappings = (
  stepData,
  layoutRef,
  addCombineColumn,
  shouldCreateCombineMappings,
  setShouldCreateCombineMappings,
  resolvers,
) => {
  useEffect(() => {
    let timer;
    if (shouldCreateCombineMappings) {
      const inputShape = stepData?.config.board.shapes.find((s) => s.type === INPUT);
      const outputShape = stepData?.config.board.shapes.find((s) => s.type === OUTPUT);
      const outputShapeCols = outputShape?.metadata.columns.map((it) => it.name);

      if (inputShape?.metadata.columns.length) {
        const connectionsToCreate = inputShape.metadata.columns.reduce((acc, col) => {
          const existing = outputShapeCols.find((name) => name.toLowerCase() === col.toLowerCase());
          if (existing) acc.push({ from: col, to: existing });
          return acc;
        }, []);

        const fn = () => {
          const container = layoutRef.current.containerEl!.getBoundingClientRect();
          const configs = connectionsToCreate.map(({ from, to }) => {
            const startId = generateStartConnectionId(inputShape.id, from);
            const endId = generateEndConnectionId(outputShape.id, to);
            const startElPos = document.getElementById(startId)!.getBoundingClientRect();
            const endElPos = document.getElementById(endId)!.getBoundingClientRect();
            const midX = (startElPos.x + startElPos.width + endElPos.x) / 2;
            const midY = (startElPos.y + endElPos.y) / 2;

            return {
              x: midX - container.x,
              y: midY - container.y,
              start: startId,
              end: endId,
              title: to,
            };
          });

          configs.forEach(({ x, y, title, start, end }, idx) => {
            const shapeId = uuid();
            addCombineColumn({
              x,
              y,
              title,
              start,
              end,
              shapeId,
              skipModalOpen: true,
              shiftLeft: idx % 3 === 0,
              shiftRight: idx % 3 === 2,
            });
          });

          resolvers.current.resolveInput();
          setShouldCreateCombineMappings(false);
        };

        if (connectionsToCreate.length) {
          const timerFn = () => {
            if (document.getElementById(generateStartConnectionId(inputShape.id, connectionsToCreate[0].from))) {
              fn();
            } else {
              timer = setTimeout(timerFn, 50);
            }
          };
          timer = setTimeout(timerFn, 50);
        } else {
          fn();
        }
      }
    }

    return () => clearTimeout(timer);
  }, [stepData, addCombineColumn, shouldCreateCombineMappings, setShouldCreateCombineMappings, layoutRef, resolvers]);
};
