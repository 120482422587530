import { memo, useContext } from 'react';
import DraggableLayout from 'modules/shared/components/DraggableLayout';
import { WizardContext, WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';
import ImportSourceModalUi from 'modules/digital-threads-mapping-wizard/import-source-modal-ui';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { useAdminOrSuperUser } from 'modules/auth/tools';

const Expand = () => {
  const { stepData, layoutRef, connection, projectId } = useContext(WizardContext);
  const { updateShape } = useContext(WizardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);
  const editable = useAdminOrSuperUser(connection, projectId);

  return (
    <>
      <DraggableLayout
        bgColour="rgb(255, 255, 255)"
        loadingBgColor="rgba(255, 255, 255, 0.2)"
        isLoading={false}
        boardData={stepData!.config.board}
        disableAdaptLayout
        adaptOnMount
        cardDragEnabled={!insidePreview}
        initial={stepData!.config.position}
        layoutRef={layoutRef}
        shapeProps={
          insidePreview || !editable
            ? {}
            : {
                onUpdate: updateShape,
              }
        }
      />
      {!insidePreview && editable && (
        <>
          <ImportSourceModalUi />
        </>
      )}
    </>
  );
};

export default memo(Expand);
